import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './AboutUs.module.css'
import Layout from '../components/layout'
import BioPreview from '../components/bio'

class AboutUs extends React.Component {
    render() {
      const siteTitle = get(this, 'props.data.site.siteMetadata.title')
      const team = get(this, 'props.data.allContentfulPerson.edges')       
  
      return (
        <Layout location={this.props.location}>
          <div style={{ background: '#fff' }}>
            <Helmet title={siteTitle} />            
            <div className="wrapper">
              <ul className="article-list">
                {team.map(({ node }) => {
                  return (
                    <li key={node.name}>
                      <BioPreview bio={node} />
                    </li>
                  )
                })}
              </ul>
              <p className="section-headline">... and other volunteers.</p>
              <h2 className="volunteers">About Us</h2>
              <p>
                The <strong>Truth2kids</strong> Team has a passion for reaching out to children with the love of God by sharing
                the Gospel with them and teaching them to live as His disciples. The Bible is central to what we
                do. Teaching is based on the authority of the Bible as the inspired Word of God which guides us
                in all aspects of life.
              </p>
              <figure class="quote">
              <blockquote>
              “All scripture is God breathed and is useful for teaching, rebuking, correcting and training in righteousness.”
              </blockquote>
              <figcaption>
                &mdash; 2 Timothy 3:16</figcaption>
              </figure>
              <p>
                The team consists of five full-time members, four of whom have qualifications as primary school
                teachers. They each gained an average of five years practical teaching experience in primary
                schools, as well experience in remedial work and adult training. All team members have been
                involved in various short term and long term missionary and community outreaches and projects
                where they gained a lot of valuable practical cross cultural experience.
              </p>
              <p>
                The whole team have various qualifications in children’s ministry and have completed several
                courses. They have been practically involved in children’s ministry for many years, some longer
                than 14 years.
              </p>              
              <p>
                Training and teaching adults who work as volunteers is also a priority in order to equip them
                with the necessary skills to assist the team. These volunteers play an important role as part of
                the team to engage with the children en various activities such as Bible clubs, camps and the
                mentoring and support of children in communities.
              </p>
              <p>
                The project team of the <strong>Truth2kids</strong> Team provide valuable support through prayer, strategic
                planning, marketing, financial management (including budgeting) and other ways of practical
                support.
              </p>
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default AboutUs

  export const aboutQuery = graphql` 
  query AboutQuery {
    allContentfulLayoutHeroImage(filter: {title: {regex: "/AboutUs/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              base64
              tracedSVG
              srcWebp
              srcSetWebp
            }          
          }
        }
      }
    }
    allContentfulPerson(sort: {fields: name, order: ASC}) {
      edges {
        node {
          name                  
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`